@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind colors;

@import '../components/Menu/styles.module.css';
@import '../components/Table/components/Pagination/styles.module.css';
@import '../components/Table/components/FilterBar/styles.module.css';
@layer utilities {
    .text-shadow {
        text-shadow: 1px 1px 0 rgb(0 0 0 / 50%);
    }
}
@layer components {
    .btn-icon {
        @apply flex items-center justify-center w-8 h-8 p-2 text-base rounded-lg lg:h-10 lg:w-10 text-slate-500 dark:text-slate-400 hover:bg-slate-200 dark:hover:bg-slate-700 focus:outline-none;
    }

    .badge {
        @apply inline-flex bg-blue-100 hover:bg-blue-200 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-1 rounded dark:bg-blue-200 dark:text-blue-800 dark:hover:bg-blue-300;
    }
}
