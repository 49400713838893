@import 'tailwindcss/components';

.table:global(.rgt-wrapper) {
    border: none;
    min-height: auto;
}
.table :global(.rgt-cell) {
    min-height: 42px;
}

.table :global(.rgt-footer-right-container) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1 1 100%;
}

.table :global(.rgt-container-overlay) {
    position: static;
    padding: 1rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 600;
}

.table :global(.rgt-cell-header-inner) {
    padding: 0;
    background: transparent;
}

.table :global(.rgt-cell-header-resizable:hover .rgt-resize-handle) {
    background-color: #e5e7eb;
}

.table :global(.rgt-cell-header-pinned-left),
.table :global(.rgt-cell-pinned-left) {
    // box-shadow: 7px 0 8px -1px #ccc;
    border-right: 2px solid #ccc;
}

.table :global(.rgt-cell-header-pinned-right),
.table :global(.rgt-cell-pinned-right) {
    // box-shadow: -7px 0 8px -1px #ccc;
    border-left: 2px solid #ccc;
}

.table :global(.rgt-cell-inner) {
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
}

.table :global(.rgt-cell-header-inner-not-pinned-right) {
    border-right-color: transparent;
}

.table :global(.rgt-cell.rgt-row-hover) {
    @apply bg-slate-100;
}

:global(.dark) .table :global(.rgt-cell.rgt-row-hover) {
    @apply bg-gray-900;
}
