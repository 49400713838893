.inputFocus {
    border-color: rgb(96 165 250);
    box-shadow: 0 0 0 #fff, 0 0 0 calc(3px) rgb(96 165 250 / 0.5), 0 1px 2px 0 rgb(0 0 0 / 0.05);
}

.inputError {
    border-color: rgb(220 38 38);

    &.inputFocus {
        box-shadow: 0 0 0 #fff, 0 0 0 calc(3px) rgb(220 38 38 / 0.5), 0 1px 2px 0 rgb(0 0 0 / 0.05) !important;
    }
}

[type='text'],
[type='email'],
[type='url'],
[type='password'],
[type='number'],
[type='date'],
[type='datetime-local'],
[type='month'],
[type='search'],
[type='tel'],
[type='time'],
[type='week'],
[multiple],
textarea,
select {
    @apply block;
    @apply w-full;
    @apply py-2 px-3;
    @apply text-gray-800 placeholder-gray-400;
    @apply rounded-md;
    @apply border border-gray-300;
    @apply shadow-sm;
    @apply hover:border-blue-300;
    &:focus {
        @extend .inputFocus;
    }
}

textarea {
    min-height: 120px;
}

body > div > .DateRangePicker_picker,
body > div > .SingleDatePicker_picker {
    position: fixed;
}

.DateRangePicker,
.SingleDatePicker {
    @apply block;
    @apply w-full;

    .DateRangePickerInput,
    .SingleDatePickerInput {
        @apply flex;
        @apply w-full;
        @apply pr-3;
        @apply rounded-md;
        @apply border border-gray-300;
        @apply shadow-sm;
        @apply hover:border-blue-300;

        .date-field-focus & {
            @extend .inputFocus;
        }

        .date-field-invalid &,
        .date-field-focus.date-field-invalid & {
            @extend .inputError;
        }

        &_calendarIcon {
            margin: 0;
            @apply px-3 py-2;

            &_svg {
                @apply fill-current;
            }
        }

        &_clearDates,
        &_clearDate {
            svg {
                width: 10px;
                fill: rgb(203 213 225);
            }

            &:hover {
                background-color: transparent;
                svg {
                    fill: rgb(100 116 139);
                }
            }
        }

        .DateRangePickerInput_arrow {
            @apply text-gray-800;
        }

        .DateInput {
            flex: 1 1 auto;

            &.DateInput_1:first-of-type {
                flex: 0 1 115px;
            }
        }

        .DateInput_input {
            @apply px-3 py-2;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5rem;
            @apply text-gray-800 placeholder-gray-400;
            border: none;
            background-color: transparent;
            box-shadow: none;
        }
    }
    .CalendarDay__selected_span,
    .CalendarDay__selected,
    .CalendarDay__selected:active {
        @apply bg-blue-600;
        @apply border-blue-700;
    }

    .CalendarDay__selected_span:active,
    .CalendarDay__selected_span:hover,
    .CalendarDay__selected:hover {
        @apply bg-blue-700;
        @apply border-blue-700;
    }

    .CalendarDay__default:hover,
    .CalendarDay__hovered_span,
    .CalendarDay__hovered_span:hover {
        @apply bg-blue-500;
        @apply border-blue-600;
        @apply text-white;
    }
}

.SingleDatePicker {
    .SingleDatePickerInput {
        .DateInput {
            &.DateInput_1:first-of-type {
                flex-grow: 1;
            }
        }
    }
}
