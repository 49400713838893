@use '../../styles/variables';

$header-height: 56px;

.content {
    @apply lg:ml-[16rem];

    min-height: calc(100vh - $header-height);
}

.content-loading {
    @apply relative;
}

@media (max-width: (variables.$breakpoint-lg - 1px)) {
    .content {
        @apply lg:ml-[16rem];

        min-height: calc(100vh - 44px);
    }

    .aside[data-open='true'] {
        @apply left-0;
    }

    .backdrop[data-open='true'] {
        @apply fixed;
        @apply inset-0;
        @apply z-[80];
        @apply bg-gray-700;
        @apply dark:bg-gray-500;
        @apply opacity-70;
    }
}
