@tailwind components;
@layer components {
    .menu-wrapper {
        @apply relative inline-block text-left;
    }

    .menu-trigger-button {
        @apply inline-flex items-center justify-center focus:outline-none text-slate-400 hover:text-slate-600 dark:text-slate-400 dark:hover:text-slate-300;
    }

    .menu-items {
        @apply absolute z-50 w-56 mt-1 bg-white border divide-y divide-gray-100 rounded-md shadow-lg border-slate-300 dark:bg-slate-800 ring-1 ring-black ring-opacity-5 focus:outline-none;
    }

    .menu-link {
        @apply flex items-center justify-start w-full px-3 py-2 text-sm font-semibold text-slate-600 hover:text-sky-600 hover:bg-slate-100 dark:text-slate-300 dark:hover:bg-gray-600 dark:hover:text-white;
    }

    .menu-link:disabled {
        cursor: not-allowed;
        pointer-events: none;
        @apply opacity-50;
    }
}
