@import '_form';

body {
    @apply font-oxygen;
    @apply text-gray-700;
    @apply dark:text-slate-300;
    @apply text-sm;
    @apply lg:text-base;
}

a {
    @apply text-sm;
    @apply font-semibold;
    @apply text-blue-600;
    @apply hover:text-blue-800;
    @apply focus:text-blue-800;
    @apply active:text-blue-900;

    @apply dark:text-blue-400;
    @apply dark:hover:text-blue-600;
    @apply dark:focus:text-blue-600;
    @apply dark:active:text-blue-700;
}

*::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

*::-webkit-scrollbar-track {
    @apply bg-slate-300;
    @apply dark:bg-slate-500;
    border-radius: 8px;
}

*::-webkit-scrollbar-thumb {
    border-radius: 8px;
    @apply bg-blue-700;
}
