@import 'tailwindcss/components';

@layer components {
    .pagination-button,
    .pagination-span,
    .pagination-current-page {
        @apply flex items-center justify-center w-8 h-8 p-2 ml-1 text-sm font-semibold border-2 rounded-lg;
    }

    .pagination-button,
    .pagination-span {
        @apply text-slate-500 dark:text-slate-300 border-slate-300 dark:border-slate-300;
    }

    .pagination-button {
        @apply focus:outline-none;
    }

    .pagination-button:disabled {
        @apply opacity-50 dark:opacity-30;
    }

    .pagination-button:not(:disabled) {
        @apply hover:bg-slate-300 hover:text-slate-600 dark:hover:bg-slate-300 dark:hover:text-slate-600;
    }

    .pagination-current-page {
        @apply text-sm text-white bg-blue-600 border-blue-600 cursor-pointer dark:bg-blue-400 dark:border-blue-400 dark:text-white;
    }
}
