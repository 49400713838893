.searchForm input {
    width: 300px;
    height: 100%;
}

.searchForm :global(.text-xs) {
    position: absolute;
    z-index: 9;
    top: calc(100% - 2px);
}

.searchForm :global(.text-xs.text-red-600) {
    @apply bg-red-600;
    color: #fff;
    right: 8px;
    left: 8px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 4px;
}

@media (max-width: 1023px) {
    .searchForm input {
        width: 100%;
        height: 32px;
    }
}
